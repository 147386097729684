@import "core/_common.scss";

label.FormInput {
  margin: 10px 0;

  .Inputlabel {
    display: block;
    font-size: 1.1rem;
    font-weight: var(--bold);
    margin-bottom: 10px;
  }

  .ClickButton {
    background: var(--gray);
    color: var(--white);
  }

  .ButtonGroup {
    .ClickButton {
      display: inline-flex;
      margin: 0;
      border-radius: 0;

      &:first-of-type {
        border-radius: var(--border-radius) 0 0 var(--border-radius);
      }

      &:last-of-type {
        border-radius: 0 var(--border-radius) var(--border-radius) 0;
      }

      & + .ClickButton {
        border-left: 1px solid var(--lightgray);
      }

      &.selected {
        background: var(--blue);
      }
    }
  }

  .FormTextInput {
    font-size: 1.6rem;
    border: 1px solid var(--lightgray);
    border-radius: var(--border-radius);
    height: 2.5rem;
    width: 400px;
  }

  .ClickSelect {
    width: 400px;
  }
}
