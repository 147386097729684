@import "core/_breakpoints.scss";
.socialMenu {
  grid-area: social;
  display: flex;
  align-items: center;
  @include breakpoint-down(sm) {
    display: none;
  }
}
:global(.nightMode) {
  .socialMenu {
    a,
    a:hover {
      svg {
        color: var(--foreground-color-1);
      }
    }
  }
}
