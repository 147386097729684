@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("./OpenSans/OpenSans-Bold.ttf");
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url("./OpenSans/OpenSans-BoldItalic.ttf");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url("./OpenSans/OpenSans-ExtraBold.ttf");
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: url("./OpenSans/OpenSans-ExtraBoldItalic.ttf");
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url("./OpenSans/OpenSans-Italic.ttf");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("./OpenSans/OpenSans-Light.ttf");
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url("./OpenSans/OpenSans-LightItalic.ttf");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./OpenSans/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: var(--bold);
  src: url("./OpenSans/OpenSans-SemiBold.ttf");
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: var(--bold);
  src: url("./OpenSans/OpenSans-SemiBoldItalic.ttf");
}
