@import "core/_common.scss";

.tagColorContainer {
  --padding: 0.125rem;
  margin: calc(-1 * var(--padding));
  > span {
    margin: var(--padding);
  }
  > .moreTags {
    color: var(--foreground-color-2);
    font-size: 0.75rem;
    font-weight: var(--bold);
    align-items: center;
    display: inline-flex;
    justify-content: center;
    height: 1.6rem;
    padding: 0.5em 0.25em;

    border: solid 0.125rem var(--foreground-color-2);
    border-radius: calc(2 * var(--border-radius));
  }
}
