@import "core/_common.scss";

.highcontrast {
  .FormCheckbox {
    .FormCheckboxSwitch {
      border: 1px solid var(--darkgray);
    }
  }
}

.FormCheckbox {
  --huge: -9999px;
  --size: 2.5rem;
  display: inline-flex;
  align-items: center;

  .FormCheckboxSwitch {
    position: relative;
    display: inline-flex;
    background: var(--lightgray);
    width: calc(2 * var(--size));
    height: var(--size);
    border-radius: var(--size);
    margin-right: 1rem;

    &::before {
      content: "";
      display: flex;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: var(--size);
      background: var(--gray);
      transition: var(--transition);
      border-radius: 50%;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    left: var(--huge);

    &:focus {
      & + .FormCheckboxSwitch {
        outline: none;

        &::after {
          --distance: -4px;

          content: "";
          position: absolute;
          top: var(--distance);
          bottom: var(--distance);
          left: var(--distance);
          right: var(--distance);

          border-radius: calc(2 * var(--size));
          border: #{2 * 2px} solid rgba(var(--gray), 0.5);
        }
      }
    }

    &:checked {
      & + .FormCheckboxSwitch {
        &::before {
          left: 50%;
          background: var(--success);
        }

        &::after {
          border-color: rgba(var(--success), 0.5);
        }
      }
    }
  }
}
