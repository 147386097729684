.buttonGroup {
  --margin: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--margin));
  & > * {
    margin: var(--margin);
  }
  &.column {
    flex-direction: row;
  }
  &.column {
    flex-direction: column;
  }
  &.alignstart {
    align-items: flex-start;
  }
  &.alignend {
    align-items: flex-end;
  }
  &.justifystart {
    justify-content: flex-start;
  }
  &.justifycenter {
    justify-content: center;
  }
  &.justifyend {
    justify-content: flex-end;
  }
  &.justifyspace-around {
    width: 100%;
    justify-content: space-around;
  }
  &.justifyspace-between {
    width: 100%;
    justify-content: space-between;
  }
}
