.container {
  background: var(--foreground-color-1);
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5em;
  .scrollbarThumb {
    background: var(--background-color-1-alpha);
    border-radius: 100px;
    left: -0.25em;
  }
  .mainInputWrap {
    display: flex;
    flex-grow: 1;
    .mainInput {
      flex-grow: 1;
      * {
        height: 100%;
      }
    }
  }
  .dropdownWrap {
    position: absolute;
    top: 2.5em;
    bottom: 0;
    min-width: 250px;
    width: auto;
    z-index: 1000;
    background: var(--foreground-color-1);
    animation-duration: 0.3s;
    box-shadow: inset 0 6px 5px -5px var(--foreground-color-1-alpha),
      1px 5px 5px var(--foreground-color-1-alpha);
    border-right: 1px solid var(--background-color-1);
    .dropdown {
      padding: 1em;
      outline: none;
      & > *:not(:last-child) {
        margin-bottom: 1em;
      }
    }
  }
}
.status {
  color: var(--background-color-1);
}
