.tooltipTrigger {
  display: inline-block;
}
.button,
a.button {
  /* general:begin */
  --height: 2.5rem;
  --borderRadius: calc(var(--height) / 2);
  --borderSize: 2px;
  --border: var(--borderSize) solid var(--click-darkblue);
  --borderPadding: 0.2rem;
  --transition: all 0.2s;
  position: relative;
  background-color: var(--foreground-color-2);
  color: var(--background-color-1);
  font-weight: var(--bold);
  font-size: calc(var(--height) / 2.5);
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  height: var(--height);
  border-radius: var(--borderRadius);
  min-width: calc(2 * var(--borderRadius));
  border: none;
  text-decoration: none;
  cursor: pointer;
  transition: var(--transition);
  &:active {
    transform: scale3d(0.9, 0.9, 1);
    filter: brightness(0.9);
  }

  .loading {
    --size: 1.25em;
    opacity: 0;
    height: var(--size);
    width: var(--size);
    position: absolute;
    left: 50%;
    margin-left: calc(var(--size) / -2);
    top: 50%;
    margin-top: calc(var(--size) / -2);
  }

  &.isLoading {
    * {
      opacity: 0;
    }
    .loading {
      opacity: 1;
      * {
        opacity: 1;
      }
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:focus {
    outline: none;
  }
  &:global(.focus-visible):not(:disabled) {
    &::before {
      --distance: calc(-2 * var(--borderSize));

      content: "";
      position: absolute;
      top: var(--distance);
      bottom: var(--distance);
      left: var(--distance);
      right: var(--distance);

      border-radius: calc(2 * var(--borderRadius));
      border: calc(2 * var(--borderSize)) solid var(--blueAlpha);

      z-index: var(--focusIndex);
    }
  }

  .label {
    padding: 0 var(--borderRadius) 0 0;
  }

  .label:first-child {
    padding: 0 var(--borderRadius);
  }

  .icon {
    position: relative;
    width: calc(2 * var(--borderRadius));
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      background: var(--background-color-1);

      --size: 0;
      height: var(--size);
      width: var(--size);
      margin-top: calc(var(--size) / 2);
      margin-left: calc(var(--size) / 2);
      transition: var(--transition);
    }
  }

  &:not(.simple):not(.ghost) {
    .label,
    .icon {
      color: var(--background-color-1);
    }
  }

  /* general:end */

  &.dark {
    background: var(--background-color-1);
    --blueAlpha: var(--background-color-1Alpha);
    .icon::before {
      background: var(--darkgray);
    }
    &:not(.danger):not(.success) {
      color: var(--foreground-color-2);
      &.ghost,
      &.simple {
        color: var(--background-color-1);
        border-color: var(--background-color-1);
      }
    }
    &.danger {
      color: var(--darkgray);
      --danger: var(--lightdanger);
      --dangerAlpha: var(--lightdangerAlpha);
    }
    &.success {
      color: var(--darkgray);
      --success: var(--lightsuccess);
      --successAlpha: var(--lightsuccessAlpha);
    }

    &:not(.simple):not(.ghost) {
      .label,
      .icon {
        color: var(--foreground-color-2);
      }
    }
  }

  &.simple {
    border-color: transparent;
    border-radius: 0;
    color: var(--foreground-color-2);
    background: transparent;
    &:hover:not(:disabled),
    &:global(.focus-visible):not(:disabled) {
      box-shadow: none;
      text-shadow: 1px 1px 2px rgba(black, 0.25);
      &.dark {
        text-shadow: 1px 1px 2px black;
      }

      .icon::before {
        background: var(--background-color-2);
      }
      &.dark {
        .icon::before {
          background: var(--background-color-1);
        }
      }
    }
    &:global(.focus-visible):not(:disabled) {
      &::before {
        --distance: calc(2 * var(--borderSize));

        content: "";
        position: absolute;
        top: var(--distance);
        bottom: 0;
        left: var(--borderRadius);
        right: var(--borderRadius);

        border-width: 0;
        border-bottom-width: calc(2 * var(--borderSize));
        border-radius: 0;
      }
    }
    &.danger {
      color: var(--danger);
    }
    &.success {
      color: var(--success);
    }
  }

  &.small {
    --height: 1.6rem;
    --borderRadius: calc(var(--height) / 2);
    --borderSize: 2px;
    --border: var(--borderSize) solid var(--click-darkblue);
    font-size: calc(var(--height) / 2);
  }
  &.big {
    --height: 3.2rem;
    --borderRadius: calc(var(--height) / 2);
    --borderSize: 2px;
    --border: var(--borderSize) solid var(--click-darkblue);
  }

  /* danger:begin */
  &.danger {
    &:not(.simple):not(.ghost) {
      background: var(--danger);
    }

    &:global(.focus-visible):not(:disabled) {
      &::before {
        border-color: var(--dangerAlpha);
      }
    }
    &:global(.focus-visible):not(:disabled),
    &:hover:not(:disabled) {
      .icon {
        color: var(--danger) !important;
      }
    }
  }

  /* danger:end */
  /* success:begin */
  &.success {
    &:not(.simple):not(.ghost) {
      background: var(--success);
    }

    &:global(.focus-visible):not(:disabled) {
      &::before {
        border-color: var(--successAlpha);
      }
    }
    &:global(.focus-visible):not(:disabled),
    &:hover:not(:disabled) {
      .icon {
        color: var(--success) !important;
      }
    }
  }

  /* success:end */

  /* simple, ghost:begin */
  &.ghost {
    background: transparent;
    border: var(--borderSize) solid var(--foreground-color-2);
    color: var(--foreground-color-2);

    &:global(.focus-visible) {
      &::before {
        --distance: calc(-3 * var(--borderSize));
      }
    }

    .icon {
      width: calc(calc(2 * var(--borderRadius)) - var(--borderSize));
      &::before {
        background: var(--background-color-2);
      }
    }
    &.dark {
      .icon::before {
        background: var(--background-color-1);
      }
    }
  }

  &.ghost,
  &.simple {
    &.danger {
      color: var(--danger);
      border-color: var(--danger);
      .icon::before {
        background: var(--danger) !important;
      }
    }

    &.success {
      color: var(--success);
      border-color: var(--success);
      .icon::before {
        background: var(--success) !important;
      }
    }
  }

  /* simple, ghost:end */

  /* hover:begin */
  &:hover:not(:disabled),
  &:global(.focus-visible):not(:disabled) {
    box-shadow: 1px 1px 5px rgba(black, 0.5);
    &:not(.simple):not(.ghost) {
      &:not(.dark) {
        color: var(--background-color-1);
      }
      .icon {
        color: var(--foreground-color-2);
      }
    }
    &.simple,
    &.ghost {
      .icon {
        color: var(--background-color-1) !important;
      }
      &.dark .icon {
        color: var(--darkgray) !important;
      }
    }
    .icon {
      & > svg {
        transform: rotate(-5deg);
      }

      &::before {
        --size: calc(var(--height) - var(--borderSize));
        height: var(--size);
        width: var(--size);
        margin-top: calc(var(--size) / -2);
        margin-left: calc(var(--size) / -2);
      }
    }
  }
  /* hover:end */
}

/* button with link rule strength problem */
a.ClickButton {
  color: var(--background-color-1);
}
