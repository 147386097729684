@import "core/_common.scss";
.feedbackHolder {
  display: flex;
  position: fixed;
  flex-direction: column;
  // justify-content: flex-end;
  bottom: 0;
  right: -0.5rem;
  width: 22rem;
  right: -2rem;
  z-index: var(--feedbacksIndex);
  .Feedback {
    position: relative;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 0.5rem;
    margin-top: 0;
    overflow: none;
    transition: var(--transition);
    &.zoomOut {
      z-index: -1;
      margin-top: -100px;
    }
    @each $status, $color in $click-status {
      &.#{$status} {
        .FeedbackStatus {
          background: $color;
        }
        .FeedbackMessage {
          border-color: $color;
          .FeedbackProgress {
            background: $color;
          }
        }
      }
    }
    .FeedbackMessage {
      position: relative;
      flex-grow: 1;
      padding: 2rem 1rem 1rem;
      background: var(--white);
      border: 1px solid var(--gray);
      border-radius: var(--border-radius) 0 0 var(--border-radius);
      overflow: hidden;
      .FeedbackType {
        position: absolute;
        display: flex;
        align-items: center;
        height: 2rem;
        top: 0;
        left: 1rem;
        padding: 0;
        margin: 0;
        font-size: 0.75rem;
      }
      .FeedbackProgress {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 0.2rem;
        width: 100%;
        background: var(--darkgray);
        transition: all 0.1s;
      }
    }
  }
  .FeedbackStatus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    background: var(--darkgray);
    color: var(--white);
    padding: 0.25rem;
    padding-right: 1.75rem;
    transition: var(--transition);
  }
}
