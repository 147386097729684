.container {
  width: max-content;
  --borderRadius: 0.5em;
  display: grid;
  height: 15em;
  width: 15em;
  grid-template: "img" 1fr "leg" auto;
  grid-row-gap: 0.5em;
  .imgContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: var(--borderRadius);
    color: var(--gray);
    background: var(--lightgray);
    transition: all 0.2s;
    &.hasImg {
      border-radius: 0;
      .imgInputLabel {
        border-radius: 0;
      }
    }
    .altBtn {
      position: absolute;
      top: -3em;
      transition: all 0.2s;
      width: 100%;
      display: flex;
      justify-content: center;
      svg {
        font-size: 1em;
      }
      &:focus-within {
        top: 0;
        background: rgba(0, 0, 0, 0.7);
      }
    }
    &:hover .altBtn {
      background: rgba(0, 0, 0, 0.7);
      top: 0;
    }
    .img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    svg {
      font-size: 5em;
    }
    .imgInput {
      position: absolute;
      left: -1000em;
    }
    .imgInputLabel {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      bottom: -5em;
      width: 100%;
      left: 0;
      border-bottom-left-radius: var(--borderRadius);
      border-bottom-right-radius: var(--borderRadius);
      transition: all 0.2s;
      cursor: pointer;
      p {
        margin: 0.25em;
      }
      svg {
        font-size: 1.25em;
      }
    }
    &:hover .imgInput:not([disabled]) + label,
    .imgInput:global(.focus-visible):not([disabled]) + label {
      left: 0;
      bottom: 0em;
      height: 30%;

      background: rgba(0, 0, 0, 0.7);
      color: var(--white);
      font-size: 1em;
    }
    .imgInput:global(.focus-visible) + label:after,
    label:hover:after {
      content: "";
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0;
      top: 0px;
      border: 4px solid var(--darkblueAlpha);
    }
  }
  .captionWrapper {
    position: relative;
    height: calc(
      var(--maxLines) * var(--lineHeight) + 2 * var(--paddingBottomTop) + 2 *
        var(--borderWidth)
    );
    .captionInput {
      position: absolute;
      font-size: 1em;
      border: none;
      border: 2px dashed var(--gray);
      line-height: var(--lineHeight);
      width: 100%;
      resize: none;
      background: none;
      border-radius: 4px;
      padding: var(--paddingBottomTop) 0.5em;
      z-index: 1;
      overflow: hidden;
      min-height: calc(
        var(--maxLines) * var(--lineHeight) + 2 * var(--paddingBottomTop) + 2 *
          var(--borderWidth)
      );
      max-height: calc(
        var(--maxLines) * var(--lineHeight) + 2 * var(--paddingBottomTop) + 2 *
          var(--borderWidth)
      );
      line-height: calc(var(--lineHeight));
      &.captionOverflowed {
        border-color: var(--danger);
      }

      &::placeholder {
        color: var(--gray);
        font-style: italic;
      }
      &.readOnly {
        border: 2px solid transparent;
      }
      &:focus,
      &:global(.focus-visible) {
        outline: none;
        border-color: var(--blue);
        border-style: solid;
        max-height: calc(
          15 * var(--lineHeight) + 2 * var(--paddingBottomTop) + 2 *
            var(--borderWidth)
        );
        background: var(--whiteAlpha);
        &.captionOverflowed {
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.7);
          border-color: var(--danger);
          border-style: solid;
        }
      }
    }
  }
}
.altTxtWrappper {
  display: block;
  position: relative;
  z-index: 2;
  padding: 0.5em;
  background: rgba(0, 0, 0, 0.7);
  border-radius: var(--borderRadius);
  transition: opacity 0.2s;
  .altTxt {
    width: 100%;
    resize: none;
    padding: 0.5em;
    border: 1px solid var(--black);
    border-radius: 4px;
    line-height: 1.5em;
    font-size: 1em;
    &::placeholder {
      color: var(--gray);
      font-weight: lighter;
      font-style: italic;
    }
    &.hidden {
      display: none;
    }
  }
}
