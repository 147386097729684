@import "core/_common.scss";
@import "core/_animations.scss";

// fonts
@import "core/fonts/OpenSans.scss";
@import "core/fonts/PlayfairDisplay.scss";
@import "core/fonts/Vollkorn.scss";
@import "core/fonts/ZillaSlab.scss";
@import "core/fonts/VAGRounded.scss";

// disabling animations for opt-in users
.reducedMotion {
  *,
  *::before,
  *::after {
    transition: none !important;
    animation: none !important;
  }
}

html {
  // --fontFamily: Roboto, "Open Sans", sans-serif;
  --fontFamily: "Open Sans", sans-serif;
  font-size: 1rem;
  font-family: var(--fontFamily);
  background-size: cover;
  min-height: 100%;
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: inherit;
}

.flow {
  --flowMargin: 2em;
  > * + * {
    margin: var(--flowMargin) 0 0 0;
  }
  &.flow-row {
    > * + * {
      margin: 0 0 0 var(--flowMargin);
    }
  }
  &.flow-padding {
    padding: var(--flowMargin) 0;
    &.flow-row {
      padding: 0 var(--flowMargin);
    }
  }
  &.flow-sm {
    --flowMargin: 1em;
  }
}

body,
body #root > * {
  color: var(--foreground-color-1);
  margin: 0;
  padding: 0;
}

body {

  a,
  a:visited,
  a:hover {
    color: var(--foreground-color-2);
    font-weight: var(--bold);
  }
  a * {
    color: inherit;
  }
}

p,
h1,
h2,
h3,
h4,
span,
div {
  margin: 0;
  padding: 0;
}

figcaption,
footer
 {
  color: black;
}


h1 {
  margin-block-start: 1.5rem;
  margin-block-end: 1.5rem;
  font-size: 2rem;
}

h2 {
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  font-size: 1.5rem;
}

h3 {
  margin-block-start: 1rem;
  margin-block-end: 0.5rem;
  font-size: 1.25rem;
}

:not(.ck-editor *) {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
h1,
h2,
h3,
h4 {
  font-weight: var(--bold);
}

// p {
//   a {
//     &,
//     &:visited,
//     &:hover {
//       color: var(--blue);
//       font-weight: var(--bold);
//       text-decoration: underline;
//     }
//   }
// }

// #root {
//   display: flex;
//   min-width: 100%;
//   margin: 0;
//   padding: 0;
//   flex-direction: column;
//   min-height: 100vh;
//   background: var(--lightgray);
//   main {
//     background: inherit;
//     flex-grow: 1;
//   }
// }

// .content {
//   @include content;
// }

:root {
  --lightblue: #{$lightblue};
  --blue: #{$blue};
  --darkblue: #{$darkblue};
  --lightgray: #{$lightgray};
  --gray: #{$gray};
  --darkgray: #{$darkgray};
  --white: #{$white};
  --black: #{$black};
  --lightdanger: #{$lightdanger};
  --danger: #{$danger};
  --lightsuccess: #{$lightsuccess};
  --success: #{$success};
  --lightwarn: #{$lightwarn};
  --warn: #{$warn};
  .nightMode {
    --danger: #{$lightdanger};
    --success: #{$lightsuccess};
  }
}

:root {
  --lightblueAlpha: #{$lightblueAlpha};
  --blueAlpha: #{$blueAlpha};
  --darkblueAlpha: #{$darkblueAlpha};
  --lightgrayAlpha: #{$lightgrayAlpha};
  --grayAlpha: #{$grayAlpha};
  --darkgrayAlpha: #{$darkgrayAlpha};
  --whiteAlpha: #{$whiteAlpha};
  --blackAlpha: #{$blackAlpha};
  --lightdangerAlpha: #{$lightdangerAlpha};
  --dangerAlpha: #{$dangerAlpha};
  --lightsuccessAlpha: #{$lightsuccessAlpha};
  --successAlpha: #{$successAlpha};
  --lightwarnAlpha: #{$lightwarnAlpha};
  --warnAlpha: #{$warnAlpha};
}

.highcontrast {
  --blue: #{$darkblue};
  --darkblue: #{$darkgray};
  --lightgray: white;
  --gray: #{$darkgray};
  --darkgray: #{$darkgray};
  --white: white;
  --black: black;
}

:root {
  --border-radius: 4px;
  --box-shadow: 1px 1px 5px rgba(black, 0.25);
  --transition: all 0.1s;
}

// especificacoes de fonte
:root {
  --bold: 600;
  --bolder: 700;
}

// definindo z-index globais
:root {
  --tooltipIndex: 10;
  --focusIndex: 20;
  --click-ck-modal: 30;
  --menuIndex: 90;
  --modalIndex: 100;
  --feedbacksIndex: 200;
  --loadingIndex: 1000;
}

@media not print {
  .ck-content {
    & + * {
      clear: both;
    }
    @include breakpoint-down(sm) {
      .image-style-align-left,
      .image-style-align-right {
        &:not(.image_resized) {
          float: none;
          width: 100%;
          max-width: 100%;
          flex-direction: column;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
