.systemStatusWrap {
  grid-area: systemStatus;
  .systemStatus {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: var(--feedbacksIndex);
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: var(--background-color-1);
    padding: 0.5em;
    background: var(--foreground-color-1);
    > * + * {
      margin-left: 1em;
    }
    &.danger {
      background: var(--danger);
    }
    &.success {
      background: var(--success);
    }
  }
}
