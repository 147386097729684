@import "core/_breakpoints.scss";
.userCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 -3px 3px -3px rgba(black, 0.25);
  padding: 0.5em 1em;
  > * + * {
    margin-top: 0.25em;
  }
  .userAvatar {
    --size: 80px;
    width: var(--size);
    height: var(--size);
    background: var(--danger);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 2em;
    font-weight: var(--bold);
    color: var(--background-color-1);
  }
  .userType {
    font-size: 0.75em;
  }
}
.userMenu {
  grid-area: user;
  > div {
    display: flex;
    align-items: center;
    height: 100%;
    .userButton {
      cursor: pointer;
      background: none;
      color: var(--white);
      display: grid;
      grid-template: "info avatar" / auto auto;
      gap: 4px;
      padding: 0;
      border: none;
      border-top-right-radius: 1000px;
      border-bottom-right-radius: 1000px;
      .info {
        grid-area: info;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        @include breakpoint-down(sm) {
          display: none;
        }
        .type {
          font-size: 0.625em;
          background: var(--warn);
          padding: 4px;
          border-radius: 4px;
          color: black;
        }
      }
      .buttonMain {
        grid-area: avatar;
        display: flex;
        align-items: center;
        overflow: hidden;
        border-radius: 1000px;
        padding: 2px;
        .avatar {
          position: relative;
          width: 44px;
          height: 44px;
          margin-right: 4px;
          &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            display: block;
            width: 0;
            height: 0;
            border-radius: 50%;
            background: var(--white);
            z-index: -1;
            border: 0 solid var(--warn);
          }
          > * {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
          > img {
            object-fit: cover;
          }
          > div {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: var(--bold);
            color: var(--background-color-1);
            background: var(--danger);
          }
        }
      }
      &:focus,
      &:global(.focus-visible) {
        outline: none;
      }
      &:hover,
      &:global(.focus-visible) {
        transition: all 0.3s ease;
        .buttonMain {
          color: var(--darkgray);
          transition: all 0.3s ease;
          .avatar {
            &::before {
              --size: 150px;
              transition: all 0.3s ease;
              width: var(--size);
              height: var(--size);
              margin-top: calc(-0.5 * var(--size));
              margin-left: calc(-0.5 * var(--size));
              border-width: 20px;
            }
          }
        }
      }
    }
  }
}
