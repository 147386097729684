.wrapper {
  --foreground-color: var(--foreground-color-1);
  --background-color: var(--background-color-1);

  display: flex;
  flex-direction: column;
  color: var(--foreground-color);
  align-items: flex-start;
  gap: 0.5em;
  .preview {
    border: 2px solid var(--foreground-color-2);
    max-height: 5em;
  }
  .name {
    max-width: 20em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  label {
    --height: 2.5rem;
    --borderRadius: calc(var(--height) / 2);
    --transition: all 0.2s;
    cursor: pointer;
    transition: var(--transition);
    display: flex;
    gap: 0.5em;
    align-items: center;
    justify-content: center;
    font-weight: var(--bold);
    color: var(--background-color-1);
    background: var(--foreground-color-2);
    height: var(--height);
    border-radius: var(--borderRadius);
    padding-right: var(--borderRadius);
    .icon {
      position: relative;
      height: var(--height);
      width: var(--height);
      transition: var(--transition);
      display: flex;
      align-items: center;
      justify-content: center;
      & > svg {
        z-index: 1;
      }
      &::before {
        content: "";
        position: absolute;
        background: var(--background-color-1);
        display: block;
        height: 0;
        width: 0;
        left: 50%;
        top: 50%;
        transition: var(--transition);
        border-radius: var(--height);
      }
    }
    &:hover {
      box-shadow: 1px 1px 5px rgba(black, 0.5);
      .icon {
        color: var(--foreground-color-2);
        &::before {
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
        }
      }
    }
    &:active {
      transform: scale3d(0.9, 0.9, 1);
      filter: brightness(0.9);
    }
  }
  input {
    position: absolute;
    left: -9999px;
    &:focus + label {
      box-shadow: 0 0 0 4px var(--background-color-2-alpha);
    }
  }
}
.errorMessage {
  font-size: 0.75em;
  color: var(--danger);
  min-height: 1rem;
}
