.header {
  grid-area: header;
  display: grid;
  grid-template:
    "a11yMenu" auto
    "menu" auto
    "breadcrumbs" auto
    "systemStatus" auto
    / 1fr;
  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
