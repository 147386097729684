@font-face {
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("ZillaSlab Regular"), local("ZillaSlab-Regular"),
    url(/core/fonts/ZillaSlab/ZillaSlab-Regular.ttf) format("woff2");
}
@font-face {
  font-family: "Zilla Slab";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("ZillaSlab Italic"), local("ZillaSlab-Italic"),
    url(/core/fonts/ZillaSlab/ZillaSlab-Italic.ttf) format("woff2");
}
@font-face {
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("ZillaSlab SemiBold"), local("ZillaSlab-SemiBold"),
    url(/core/fonts/ZillaSlab/ZillaSlab-SemiBold.ttf) format("woff2");
}
@font-face {
  font-family: "Zilla Slab";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("ZillaSlab SemiBoldItalic"), local("ZillaSlab-SemiBoldItalic"),
    url(/core/fonts/ZillaSlab/ZillaSlab-SemiBoldItalic.ttf) format("woff2");
}
@font-face {
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("ZillaSlab Bold"), local("ZillaSlab-Bold"),
    url(/core/fonts/ZillaSlab/ZillaSlab-Bold.ttf) format("woff2");
}
@font-face {
  font-family: "Zilla Slab";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local("ZillaSlab BoldItalic"), local("ZillaSlab-BoldItalic"),
    url(/core/fonts/ZillaSlab/ZillaSlab-BoldItalic.ttf) format("woff2");
}
