$click-blue: #3394db;
$click-darkblue: #005a8f;
$click-lightblue: #8ac1ea;
$click-orange: #dc8f20;
$click-white: #fdfdfd;
$click-black: #303030;
$click-darkgray: #333333;
$click-gray: #777777;
$click-gray-10: #e6e6e6;
$click-gray-30: #b3b3b3;
$click-gray-40: #999999;

$lightblue: #8ac1ea;
$blue: #3394db;
$darkblue: #005a8f;
$lightgray: #e6e6e6;
$gray: #777777;
$darkgray: #333333;
$white: #fdfdfd;
$black: #171717;

$lightblueAlpha: rgba($lightblue, 0.5);
$blueAlpha: rgba($blue, 0.5);
$darkblueAlpha: rgba($darkblue, 0.5);
$lightgrayAlpha: rgba($lightgray, 0.5);
$grayAlpha: rgba($gray, 0.5);
$darkgrayAlpha: rgba($darkgray, 0.5);
$whiteAlpha: rgba($white, 0.5);
$blackAlpha: rgba($black, 0.5);

$palx-darkblue: #174262;
$palx-blue: #318fd2;
$palx-lightblue: #b3d8f2;

$palx-darkindigo: #252fa0;
$palx-indigo: #7680e7;
$palx-lightindigo: #cdd0f6;

$palx-darkviolet: #512293;
$palx-violet: #a170e6;
$palx-lightviolet: #ddccf6;

$palx-darkfuschia: #6a1a71;
$palx-fuschia: #d44fe0;
$palx-lightfuschia: #f1c5f5;

$palx-darkpink: #731b4e;
$palx-pink: #e154a6;
$palx-lightpink: #f5c6e1;

$palx-darkred: #791c24;
$palx-darkishred: #ae3d46;
$palx-red: #e25d68;
$palx-lightred: #f5c8cc;

$palx-darkorange: #5f3516;
$palx-orange: #cd7130;
$palx-lightorange: #f1ccb1;

$palx-darkyellow: #454010;
$palx-yellow: #958b23;
$palx-lightyellow: #e2d658;

$palx-darklime: #2f4610;
$palx-lime: #679723;
$palx-lightlime: #aee362;

$palx-darkgreen: #164911;
$palx-darkishgreen: #23731b;
$palx-green: #2f9d25;
$palx-lightgreen: #89e980;

$palx-darkteal: #114928;
$palx-teal: #249c56;
$palx-lightteal: #7de8aa;

$palx-darkcyan: #114742;
$palx-cyan: #24998f;
$palx-lightcyan: #70e6dc;

$palx-darkgray: #384046;
$palx-gray: #7b8b98;
$palx-lightgray: #dde1e5;

$lightdanger: $palx-lightred;
$danger: $palx-darkishred;
$lightsuccess: $palx-lightgreen;
$success: $palx-darkishgreen;
$warn: #dc8f20;
$lightwarn: lighten($warn, 25);

$lightdangerAlpha: rgba($lightdanger, 0.5);
$dangerAlpha: rgba($danger, 0.5);
$lightsuccessAlpha: rgba($lightsuccess, 0.5);
$successAlpha: rgba($success, 0.5);
$lightwarnAlpha: rgba($lightwarn, 0.5);
$warnAlpha: rgba($warn, 0.5);

$click-danger: $danger;
$click-success: $success;
$click-lightsuccess: $lightsuccess;

$click-subjects: (
  his,
  geo,
  ere,
  fil,
  soc,
  efi,
  cie,
  bio,
  qui,
  fis,
  mat,
  ing,
  esp,
  por,
  lit,
  art,
  tra,
  inf,lib
);

:root {
  --his: #{$palx-red};
  --geo: #{$palx-orange};
  --ere: #{$palx-yellow};
  --fil: #{$palx-yellow};
  --soc: #{$palx-yellow};
  --efi: #{$palx-lime};
  --cie: #{$palx-green};
  --bio: #{$palx-green};
  --inf: #{$palx-green};
  --qui: #{$palx-teal};
  --fis: #{$palx-cyan};
  --mat: #{$palx-blue};
  --ing: #{$palx-indigo};
  --esp: #{$palx-indigo};
  --por: #{$palx-violet};
  --lit: #{$palx-fuschia};
  --art: #{$palx-pink};
  --tra: #{$palx-gray}; --tra: #{$palx-gray};
 
  .nightMode {
    --his: #{$palx-lightred};
    --geo: #{$palx-lightorange};
    --ere: #{$palx-lightyellow};
    --fil: #{$palx-lightyellow};
    --soc: #{$palx-lightyellow};
    --efi: #{$palx-lightlime};
    --cie: #{$palx-lightgreen};
    --bio: #{$palx-lightgreen};
    --inf: #{$palx-lightgreen};
    --qui: #{$palx-lightteal};
    --fis: #{$palx-lightcyan};
    --mat: #{$palx-lightblue};
    --ing: #{$palx-lightindigo};
    --esp: #{$palx-lightindigo};
    --por: #{$palx-lightviolet};
    --lit: #{$palx-lightfuschia};
    --art: #{$palx-lightpink};
    --tra: #{$palx-lightgray};
  }
}

$subjects-colors: (
  his: (
    dark: $palx-darkred,
    regular: $palx-red,
    light: $palx-lightred,
  ),
  geo: (
    dark: $palx-darkorange,
    regular: $palx-orange,
    light: $palx-lightorange,
  ),
  ere: (
    dark: $palx-darkyellow,
    regular: $palx-yellow,
    light: $palx-lightyellow,
  ),
  fil: (
    dark: $palx-darkyellow,
    regular: $palx-yellow,
    light: $palx-lightyellow,
  ),
  soc: (
    dark: $palx-darkyellow,
    regular: $palx-yellow,
    light: $palx-lightyellow,
  ),
  efi: (
    dark: $palx-darklime,
    regular: $palx-lime,
    light: $palx-lightlime,
  ),
  cie: (
    dark: $palx-darkgreen,
    regular: $palx-green,
    light: $palx-lightgreen,
  ),
  bio: (
    dark: $palx-darkgreen,
    regular: $palx-green,
    light: $palx-lightgreen,
  ),
  inf: (
    dark: $palx-darkgreen,
    regular: $palx-green,
    light: $palx-lightgreen,
  ),
  qui: (
    dark: $palx-darkteal,
    regular: $palx-teal,
    light: $palx-lightteal,
  ),
  fis: (
    dark: $palx-darkcyan,
    regular: $palx-cyan,
    light: $palx-lightcyan,
  ),
  mat: (
    dark: $palx-darkblue,
    regular: $palx-blue,
    light: $palx-lightblue,
  ),
  ing: (
    dark: $palx-darkindigo,
    regular: $palx-indigo,
    light: $palx-lightindigo,
  ),
  esp: (
    dark: $palx-darkindigo,
    regular: $palx-indigo,
    light: $palx-lightindigo,
  ),
  por: (
    dark: $palx-darkviolet,
    regular: $palx-violet,
    light: $palx-lightviolet,
  ),
  lit: (
    dark: $palx-darkfuschia,
    regular: $palx-fuschia,
    light: $palx-lightfuschia,
  ),
  art: (
    dark: $palx-darkpink,
    regular: $palx-pink,
    light: $palx-lightpink,
  ),
  lib: (
    dark: $palx-darkblue, // Azul escuro para profundidade e contraste
    regular: $palx-blue, // Azul médio para equilíbrio
    light: $palx-lightblue, // Azul claro para suavidade e clareza
  ),
  tra: (
    dark: $palx-darkgray,
    regular: $palx-gray,
    light: $palx-lightgray,
  ),
);

$click-status: (
  error: $danger,
  success: $success,
  warning: $warn,
);

:root {
  --foreground-color-1: #{$palx-darkgray};
  --foreground-color-1-alpha: #{rgba($palx-darkgray, 0.25)};
  --foreground-color-1-light: #{$palx-lightgray};
  --foreground-color-2: #{$click-blue};
  --foreground-color-2-alpha: #{rgba($click-blue, 0.5)};
  --foreground-color-3: #{$white};

  --background-color-1: #{$white};
  --background-color-1-alpha: #{rgba($white, 0.5)};
  --background-color-2: #{$click-blue};
  --background-color-2-alpha: #{rgba($click-blue, 0.5)};
  --background-color-3: #{$click-darkblue};
  --background-color-3-alpha: #{rgba($click-darkblue, 0.5)};
  --background-color-4: #{$click-gray-10};

  --grassColor: #9acc00;
  --mudColor: saddlebrown;
  --mudColor-alpha: #{rgba(saddlebrown, 0.5)};

  --flowerPetal: #f00000;
  --flowerCore: #efc10e;

  --sparkColor: #f68b1e;

  --bushColor: #1d7208;

  --balloonBackground: #{$white};

  --wood: #8b4513;
  --woodHighlight: #4e2103;
  --riverWater: #{$click-blue};
  --stone: #cccccc;

  --warn: #{$warn};
  .nightMode {
    --foreground-color-1: #{$white};
    --foreground-color-1-alpha: #{rgba($white, 0.25)};
    --foreground-color-1-light: #{$palx-darkgray};
    --foreground-color-2: #{$lightblue};
    --foreground-color-2-alpha: #{rgba($lightblue, 0.5)};

    --background-color-1: #{$black};
    --background-color-1-alpha: #{rgba($black, 0.5)};
    --background-color-2: #{$palx-darkgray};
    --background-color-3: #{$white};
    --background-color-3-alpha: #{rgba($white, 0.5)};
    --background-color-4: #{$click-darkgray};

    --grassColor: #05211a;
    --mudColor: #010000;
    --mudColor-alpha: #{rgba(#010000, 0.5)};

    --flowerPetal: #363357;
    --flowerCore: #ffffe0;

    --sparkColor: #ff0;

    --bushColor: #0b3a00;

    --balloonBackground: #081824;

    --wood: #1d0c00;
    --woodHighlight: #140800;
    --riverWater: #{$click-darkblue};
    --stone: #3a3a3a;
  }
  @each $subject, $colors in $subjects-colors {
    .#{$subject} {
      --foreground-color-2: #{map-get($colors, "regular")};
      --background-color-2: #{map-get($colors, "regular")};
      --background-color-3: #{map-get($colors, "dark")};
      --background-color-3-alpha: #{rgba(map-get($colors, "dark"), 0.5)};
      --subject: #{map-get($colors, "regular")};
      --darksubject: #{map-get($colors, "dark")};
      --lightsubject: #{map-get($colors, "light")};
      --subjectAlpha: #{rgba(map-get($colors, "regular"), 0.5)};
      --darksubjectAlpha: #{rgba(map-get($colors, "dark"), 0.5)};
      --lightsubjectAlpha: #{rgba(map-get($colors, "light"), 0.5)};
      &.nightMode {
        --foreground-color-2: #{map-get($colors, "light")};
        --background-color-2: #{map-get($colors, "dark")};
      }
    }
  }
}
