@import "core/_common.scss";

.ClickMultiselectDropdown {
  --foreground-color: var(--black);
  --background-color: var(--white);
  --border-size: 2px;

  color: var(--foreground-color);

  &.dark {
    --foreground-color: var(--white);
    --background-color: var(--black);
  }

  display: inline-block;
  overflow-y: auto;
  max-height: 300px;
  min-width: 200px;
  display: flex;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 1rem;
  }

  *[role="grid"].tableMode {
    display: flex;
    flex-direction: column;

    > *[role="row"] {
      display: flex;
      width: 100%;
      align-items: center;

      &:not(:first-child) {
        margin-top: 0.25rem;
      }

      > *[role="gridcell"] {
        display: inline-flex;
        align-items: stretch;
        height: 100%;
        padding: 0 0.5rem;

        &:first-child {
          position: relative;
          flex-grow: 1;
          display: inline-flex;
          justify-content: space-between;
          margin-left: -0.5rem;
          height: 100%;

          &:focus {
            outline: none;
          }

          &:global(.focus-visible)::before {
            content: "";
            box-sizing: content-box;
            position: absolute;
            top: calc(-1 * var(--border-size));
            right: calc(-1 * var(--border-size));
            bottom: calc(-1 * var(--border-size));
            left: calc(-1 * var(--border-size));
            border: var(--border-size) solid var(--blue);
          }
        }

        &:last-child {
          margin-right: -0.5rem;
        }
      }
    }
  }

  *[role="grid"].tagsMode {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 200px;
    height: 51px;
    margin: 0 -0.25rem;
    > *[role="row"] {
      display: flex;
      border-radius: var(--border-radius);
      background: var(--foreground-color);
      color: var(--background-color);
      align-items: center;
      margin: 0.25rem;
      padding: 0.25rem 0 0.25rem 0.5rem;
      height: 1.5rem;
      font-size: 0.75rem;
      font-weight: var(--bold);

      > *[role="gridcell"] {
        position: relative;

        &:focus {
          outline: none;
        }
      }

      > *[role="gridcell"]:global(.focus-visible)::before {
        content: "";
        box-sizing: content-box;
        position: absolute;
        top: calc(-2 * var(--border-size));
        right: calc(-2 * var(--border-size));
        bottom: calc(-2 * var(--border-size));
        left: calc(-2 * var(--border-size));
        border: var(--border-size) solid var(--blue);
      }
    }
  }
}

.helpText {
  display: block;
  font-size: 0.75em;
  padding-bottom: 0.25em;
}

.label {
  font-weight: var(--bold);
  color: rgb(128, 128, 128);
}

.errorMessage {
  font-size: 0.75em;
  color: var(--danger);
  min-height: 1rem;
}
