@import "core/_common.scss";

section.FormPanel {
  background: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border: solid 1px var(--lightgray);
  padding: 25px;
  margin: 25px 0;
  border-radius: var(--border-radius);
}
