.container {
  --border: 2px;
  --size: 1em;
  --distance: 3px;
  .title {
    font-weight: var(--bold);
    padding: 0;
    margin: 0;
  }
  .helpText {
    margin-top: 0;
    font-size: 0.75em;
  }
  .checkable {
    display: flex;
    align-items: center;
    --margin: 0.25em;
  }
  input {
    font-size: 1em;
    position: relative;
    width: var(--size);
    height: var(--size);
    margin: 0;
    &:before {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--background-color-1);
      border: var(--border) solid var(--foreground-color-1);
    }
    &:checked:after {
      content: "";
      --distance: 0.25em;
      position: absolute;
      left: var(--distance);
      right: var(--distance);
      top: var(--distance);
      bottom: var(--distance);
      background: var(--foreground-color-2);
    }
    &:focus {
      outline: none;
    }
  }
  label {
    position: relative;
  }
  &:not(.switch) {
    label {
      margin-left: var(--margin);
    }
    input {
      &:global(.focus-visible) + label {
        &:before {
          --focusWeight: 4px;
          --leftDistance: calc(
            var(--size) + var(--margin) + var(--focusWeight)
          );
          content: "";
          position: absolute;
          top: calc(-0.5 * var(--focusWeight));
          bottom: calc(-0.5 * var(--focusWeight));
          right: calc(-1 * var(--focusWeight));
          left: calc(-1 * var(--leftDistance));
          border: 4px solid var(--foreground-color-2-alpha);
        }
      }
    }
  }
}

.container.dark {
  color: var(--background-color-1);
  input:before {
    border: 1px solid var(--background-color-1);
  }
  p {
    color: inherit;
  }
}

.container.switch {
  --size: calc(var(--height) - calc(2 * var(--distance)) - 2 * var(--border));
  --height: 1.5em;
  --distance: calc(1 * var(--border));
  input {
    position: absolute;
    left: -1000000px;

    &:checked {
      & + label .switch {
        background: var(--background-color-2);
      }
      & + label .switch::before {
        left: calc(var(--height) + var(--distance));
        background: var(--background-color-1);
      }
    }
    &:global(.focus-visible) + label .switch {
      &::before {
        content: "";
        box-shadow: 0 0 0 4px var(--foreground-color-2-alpha);
      }
    }
    &:checked:global(.focus-visible) + label .switch {
      &::before {
        box-shadow: 0 0 0 4px var(--background-color-1-alpha);
      }
    }
  }
  label {
    display: flex;
    align-items: center;
    .switch {
      position: relative;
      display: block;
      border: var(--border) solid var(--foreground-color-1);
      width: calc(2 * var(--height));
      height: var(--height);
      border-radius: calc(var(--height) / 2);
      background: var(--background-color-1);
      transition: all 0.3s;
      &::before {
        content: "";
        position: absolute;
        border-radius: 50%;
        background: var(--foreground-color-1);
        width: var(--size);
        top: var(--distance);
        bottom: var(--distance);

        left: var(--distance);
        transition: all 0.3s ease-in-out;
      }
    }

    p {
      margin-left: var(--margin);
    }
  }
}

.errorMessage {
  font-size: 0.75em;
  color: var(--danger);
  min-height: 1rem;
}

:global(.nightMode) {
  .container.switch input:checked + label .switch {
    background: var(--foreground-color-2);
  }
}
