.container {
  --foreground-color: var(--black);
  --background-color: var(--white);
  --border-radius: 0.25rem;
  --border-size: 2px;
  --focus-color: var(--blackAlpha);

  color: var(--foreground-color);
  &.dark {
    --foreground-color: var(--white);
    --background-color: var(--black);
    --focus-color: var(--whiteAlpha);
  }
  .wrapper {
    display: inline-block;
    width: 100%;
    min-width: 100%;
    .textInput {
      font-size: 1em;
      border-radius: var(--border-radius);
      border: solid var(--border-size) var(--foreground-color);
      background: var(--white);
      color: var(--black);
      width: 100%;
      padding: 0.25em 0.5em;
      &::placeholder {
        color: var(--gray);
        font-style: italic;
      }
      &:focus {
        outline: none;
        border-color: var(--blue);
      }
    }
    .listbox {
      margin: 0;
      padding: 0;
      background: var(--white);
      color: var(--black);
      max-height: 10em;
      overflow-y: auto;
      margin-top: 0.25em;
      border-radius: var(--border-radius);
      border: var(--border-size) solid var(--blue);
      box-shadow: var(--box-shadow);
      z-index: 10;
      &:not(.expanded) {
        display: none;
      }

      &:focus {
        outline: none;
      }

      > li {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 0.25em 0.25em 0.25em 0.5em;
        cursor: pointer;
        white-space: nowrap;
        &.focus {
          background-color: var(--blue);
          color: var(--white);
        }
        &:not(.focus):hover {
          background-color: var(--lightblue);
        }
      }
    }
  }

  label {
    font-weight: var(--bold);
  }
  .helpText {
    display: block;
    font-size: 0.75em;
    padding-bottom: 0.25em;
  }
}
.errorMessage {
  font-size: 0.75em;
  color: var(--danger);
  min-height: 1rem;
}
