.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.resetPasswordContainer {
  background: var(--background-color-1);
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);

  padding: 1.5rem;
  width: 400px;
  // height: 350px;

  border-radius: 1em;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  form {
    width: 100%;
  }
}

.inputGroup {
  margin: 10px 0;
}

.input {
  font-size: 1rem;

  border: 1px solid var(--darkgray);
  border-radius: var(--border-radius);

  min-height: 2rem;
  height: 2rem;
  width: 100%;

  background: var(--white);

  padding: 0.25rem 0.5rem;

  transition: all 0.3s;

  margin: 5px 0;
}

.inputLabel {
  display: block;
  font-size: 1.1rem;
  font-weight: var(--bold);
}

.error {
  font-weight: 400;
  font-size: 0.75rem;

  color: var(--danger);
}

.submitButton {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 20px;
}

.loginLink {
  display: block;

  margin-top: 20px;

  text-align: center;
}
