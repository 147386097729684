@font-face {
  font-family: "Vollkorn";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Vollkorn-Regular"),
    url(/core/fonts/Vollkorn/Vollkorn-Regular.ttf) format("woff2");
}
@font-face {
  font-family: "Vollkorn";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Vollkorn-Italic"),
    url(/core/fonts/Vollkorn/Vollkorn-Italic.ttf) format("woff2");
}
@font-face {
  font-family: "Vollkorn";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Vollkorn-Bold"),
    url(/core/fonts/Vollkorn/Vollkorn-Bold.ttf) format("woff2");
}
@font-face {
  font-family: "Vollkorn";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Vollkorn-BoldItalic"),
    url(/core/fonts/Vollkorn/Vollkorn-BoldItalic.ttf) format("woff2");
}
@font-face {
  font-family: "Vollkorn";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Vollkorn-Black"),
    url(/core/fonts/Vollkorn/Vollkorn-Black.ttf) format("woff2");
}
@font-face {
  font-family: "Vollkorn";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local("Vollkorn-BlackItalic"),
    url(/core/fonts/Vollkorn/Vollkorn-BlackItalic.ttf) format("woff2");
}
