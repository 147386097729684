@import "core/_breakpoints.scss";
.submenu {
  z-index: 10;
  background: var(--background-color-1);
  box-shadow: 0 3px 5px rgba(black, 0.25);
  color: var(--foreground-color-1);
  animation-duration: 0.3s;
  @include breakpoint-up(md) {
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  .submenu {
    @include breakpoint-up(md) {
      border-bottom-left-radius: 0;
      border-top-right-radius: 1em;
      > ul {
        border-bottom-left-radius: 0;
        border-top-right-radius: 1em;
      }
    }
  }
  @include breakpoint-down(sm) {
    width: 100%;
  }
  &.hidden {
    display: none;
  }
  .submenuArrow {
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-bottom-color: var(--background-color-1);
    top: -13px;
  }
  > ul {
    overflow: hidden;
    @include breakpoint-up(md) {
      border-bottom-left-radius: 1em;
      border-bottom-right-radius: 1em;
    }
    > li {
      position: relative;
      a,
      button {
        display: flex;
        align-items: center;
        height: 3em;
        width: 100%;
        background: none;
        border: none;
        color: var(--foreground-color-2);
        font-weight: var(--bold);
        cursor: pointer;
        padding: 0 2em 0 1em;
        &[aria-haspopup="true"] {
          padding: 0 1em;
        }
        &:hover,
        &:global(.focus-visible),
        &[aria-expanded="true"] {
          transition: all 0.3s ease;
          outline: none;
          box-shadow: 0 0 15px rgba(black, 0.25);
          padding: 1.5em;
          &[aria-haspopup="true"] {
            padding: 0 0.5em 0 1.5em;
          }
          background: var(--foreground-color-1);
          color: var(--background-color-1);
        }
        > * + * {
          margin-left: 1em;
        }
        .icon {
          padding-left: 0.5em;
          margin-left: auto;
          @include breakpoint-down(sm) {
            margin-right: 1em;
          }
          svg {
            transition: transform 0.3s ease;
            @include breakpoint-down(sm) {
              transform: rotate(90deg);
            }
          }
        }
        &[aria-expanded="true"] {
          .icon svg {
            transform: rotate(180deg);
            @include breakpoint-down(sm) {
              transform: rotate(270deg);
            }
          }
        }
      }
      + li {
        &::before {
          content: "";
          display: block;
          position: absolute;
          left: 2px;
          right: 2px;
          top: 0;
          height: 1px;
          background: var(--foreground-color-1-alpha);
        }
      }
    }
  }
}

:global(.nightMode) {
  .submenu {
    border: 1px solid var(--background-color-2);
    border-top: none;
  }
}
