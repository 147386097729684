@import "core/_breakpoints.scss";

@keyframes cloudsFade {
  100% {
    opacity: 1;
  }
}
.background {
  opacity: 0;
  animation: cloudsFade ease forwards;
  animation-duration: 3s;
  animation-delay: 0.5s;
  .clouds {
    color: var(--whiteAlpha);
    animation-duration: 50s;
    > svg {
      transform: scaleX(-1);
      &:nth-child(1) {
        font-size: 50px;
        bottom: 30%;
        left: 1%;
      }
      &:nth-child(2) {
        font-size: 150px;
        bottom: 50%;
        left: 15%;
      }
      &:nth-child(3) {
        font-size: 60px;
        bottom: 60%;
        left: 40%;
      }
      &:nth-child(4) {
        font-size: 70px;
        bottom: 40%;
        left: 60%;
      }
      &:nth-child(5) {
        font-size: 100px;
        bottom: 40%;
        left: 80%;
      }
    }
  }
}
.foreground {
  opacity: 0;
  animation: cloudsFade ease forwards;
  animation-duration: 3s;
  .clouds {
    color: var(--white);
    animation-duration: 40s;
    > svg {
      &:nth-child(1) {
        font-size: 30px;
        bottom: 40%;
        left: 10%;
      }
      &:nth-child(2) {
        font-size: 50px;
        bottom: 55%;
        left: 15%;
      }
      &:nth-child(3) {
        font-size: 35px;
        bottom: 35%;
        left: 45%;
      }
      &:nth-child(4) {
        font-size: 60px;
        bottom: 50%;
        left: 55%;
      }
      &:nth-child(5) {
        font-size: 55px;
        bottom: 45%;
        left: 70%;
      }
    }
  }
}
.clouds {
  // border: 1px solid red;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: opacity 0.5s ease;
  @keyframes cloudsMove {
    100% {
      transform: translateX(-100%);
    }
  }
  animation: cloudsMove infinite linear;
  &:last-child {
    left: 100%;
  }
  > svg {
    position: absolute;
  }
}
.sun {
  position: absolute;
  left: -1%;
  top: 10%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #efc10e;
  z-index: -1;
  transition: background 0.3s ease;
}
.moon {
  position: absolute;
  color: transparent;
  font-size: 50px;
  top: 10%;
  left: 90vw;
  z-index: -1;
  transition: color 0.3s ease;
}
:global(.nightMode) {
  .background {
    .clouds {
      opacity: 0.2;
    }
  }
  .sun {
    background: transparent;
  }
  .moon {
    color: lightyellow;
  }
  .foreground {
    .clouds {
      opacity: 0;
    }
  }
}
