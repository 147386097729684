@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Playfair Display Regular"), local("PlayfairDisplay-Regular"),
    url(/core/fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf)
      format("woff2");
}
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Playfair Display Italic"), local("PlayfairDisplay-Italic"),
    url(/core/fonts/PlayfairDisplay/PlayfairDisplay-Italic.ttf) format("woff2");
}
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Playfair Display Bold"), local("PlayfairDisplay-Bold"),
    url(/core/fonts/PlayfairDisplay/PlayfairDisplay-Bold.ttf) format("woff2");
}
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Playfair Display BoldItalic"), local("PlayfairDisplay-BoldItalic"),
    url(/core/fonts/PlayfairDisplay/PlayfairDisplay-BoldItalic.ttf)
      format("woff2");
}
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Playfair Display Black"), local("PlayfairDisplay-Black"),
    url(/core/fonts/PlayfairDisplay/PlayfairDisplay-Black.ttf) format("woff2");
}
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local("Playfair Display BlackItalic"),
    local("PlayfairDisplay-BlackItalic"),
    url(/core/fonts/PlayfairDisplay/PlayfairDisplay-BlackItalic.ttf)
      format("woff2");
}
