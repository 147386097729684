.a11yMenu {
  grid-area: a11yMenu;
  background: var(--background-color-3);
  position: relative;
  .a11yButton {
    gap: 0.25rem;
    svg:not(.menuIndicator) {
      transform: scale(1.75);
    }
    .menuIndicator {
      transition: all 0.3s ease;
      margin-left: 0.5em;
    }
    &[aria-expanded="true"] {
      .menuIndicator {
        transform: rotate(180deg);
      }
    }
  }
}

.a11yModal {
  border-radius: 0 20px 20px 20px;
  background: var(--background-color-3);
  animation: show 0.6s ease forwards;
  z-index: var(--menuIndex);
  overflow: hidden;
  .checkbox {
    margin: 1rem;
    color: var(--background-color-1);
    display: flex;
    gap: 1em;
    align-items: center;

    label p {
      white-space: nowrap;
    }
  }
}

:global(.nightMode) {
  .a11yMenu {
    button:hover {
      svg:not(.menuIndicator) {
        color: var(--foreground-color-1);
      }
    }
  }
}

@keyframes show {
  from {
    max-width: 0;
    max-height: 0;
  }
  to {
    max-width: 100vw;
    max-height: 100vh;
  }
}
