@import "core/_breakpoints.scss";
.navigation {
  grid-area: navigation;
  height: 100%;
  display: grid;
  align-items: center;
  .toggleNavigation {
    margin-left: -0.5em;
    @include breakpoint-up(md) {
      display: none;
    }
    &[aria-expanded="true"] {
      position: fixed;
      &,
      * {
        z-index: calc(var(--menuIndex) + 2);
      }
      top: 0;
      right: 15%;
    }
  }
}

:global(.nightMode) {
  .toggleNavigation {
    svg {
      color: white;
    }
  }
}
