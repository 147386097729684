@import "core/_common.scss";

.ClickDropdown {
  --foreground-color: var(--foreground-color-1);
  --background-color: var(--background-color-1);
  --border-radius: 0.25rem;
  --border-size: 2px;
  --arrowWidth: 1.5em;

  color: var(--foreground-color);

  &.dark {
    --foreground-color: var(--background-color-1);
    --background-color: var(--foreground-color-1);
    > button:not(.noStyle) {
      border: var(--border-size) solid var(--background-color-1);
    }
  }

  display: inline-block;
  width: 100%;
  position: relative;

  > .noStyle {
    position: relative;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    font: inherit;
    background: none;
    display: inline;
  }
  > button:not(.noStyle) {
    position: relative;
    padding: 0 calc(var(--arrowWidth) + 0.25em) 0 0.5em;
    background: var(--background-color);
    color: var(--foreground-color);
    border-radius: var(--border-radius);
    border: var(--border-size) solid var(--foreground-color);
    font-size: 1em;
    min-width: 8em;
    width: 100%;
    height: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    > span:first-of-type {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:focus:not(:disabled),
    &.expanded:not(:disabled) {
      outline: none;
      border-color: var(--blue);

      > .icon {
        background: var(--blue);
        color: var(--white);
      }
    }

    > .icon {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: var(--arrowWidth);
      font-size: calc(var(--arrowWidth) / 2);
      background: var(--gray-30);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: var(--darkgray);
    }
  }

  > ul {
    margin: 0;
    padding: 0;
    background: var(--white);
    border-top: 0;
    max-height: 10em;
    overflow-y: auto;
    width: 100%;
    position: absolute;
    top: 100%;
    margin-top: 0.25em;
    color: var(--black);
    border-radius: var(--border-radius);
    border: var(--border-size) solid var(--blue);
    box-shadow: var(--box-shadow);
    z-index: 10;

    &:not(.expanded) {
      display: none;
    }

    &:focus {
      outline: none;
    }

    > li {
      position: relative;
      padding: 0.25em 0.5em;
      cursor: pointer;
      display: inline-flex;
      width: 100%;
      justify-content: space-between;

      &.focus {
        background-color: var(--blue);
        color: var(--white);
      }
      &:not(.focus):hover {
        background-color: var(--lightblue);
      }
    }
  }
}

.helpText {
  display: block;
  font-size: 0.75em;
  padding-bottom: 0.25em;
}

.label {
  font-weight: var(--bold);
  margin-bottom: 0.25rem;
  display: inline-block;
}

.errorMessage {
  font-size: 0.75em;
  color: var(--danger);
  min-height: 1rem;
}
