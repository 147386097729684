@import "core/_common.scss";

.ClickPagination {
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint-down(sm) {
    width: calc(100% + 20px);
    margin: 0 -20px;
  }

  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--foreground-color-1);
    width: 3rem;
    height: 2.5rem;
    color: var(--background-color-1);

    &.arrow-left {
      border-radius: 2.5rem 0 0 2.5rem;
    }

    &.arrow-right {
      border-radius: 0 2.5rem 2.5rem 0;
    }

    &.disabled {
      background: var(--foreground-color-1-alpha);
    }
  }

  .ClickPage {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 2.5rem;
    color: var(--foreground-color-1);
    text-decoration: none;

    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: var(--foreground-color-1);
      transition: var(--transition);
    }

    &:hover:not(.jump):not(.arrow) {
      &:before {
        height: 4px;
      }
    }

    &.current {
      color: var(--foreground-color-2);
      font-weight: var(--bold);

      &:before {
        background: var(--foreground-color-2);
        height: 4px;
      }
    }

    &.hidable {
      @include breakpoint-down(sm) {
        display: none;
      }
    }

    &.jump {
      color: var(--foreground-color-1-alpha);

      &:before {
        background: var(--foreground-color-1-alpha);
      }
    }
  }
}
