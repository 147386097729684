@import "core/_common.scss";

.ClickPanel {
  border-radius: calc(2 * var(--border-radius));
  background: var(--background-color-1);
  padding: 1.5rem 1rem;

  &.small {
    width: 200px;
  }

  &.medium {
    width: 400px;
  }

  &.large {
    width: 1024px;
  }

  &.fitParent {
    width: 100%;
  }
}

.highcontrast {
  .ClickPanel {
    border: 1px solid var(--gray);
  }
}
