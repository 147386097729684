@import "core/_common.scss";
.ClickSelect {
  display: flex;
  height: 2.5em;
  font-weight: var(--bold);
  color: var(--black);
  .ClickSelect__control {
    min-width: 200px;
  }
  .ClickSelect__multi-value {
    background: none;
  }
  .ClickSelect__multi-value__remove {
    background-color: hsl(0, 0%, 90%);
  }
}
