@import "core/_breakpoints.scss";
.notificationsMenu {
  grid-area: notifications;
  .notificationsBtn {
    .icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        position: absolute;
        z-index: 1;
        path {
          pointer-events: none;
        }
      }
      color: var(--white);
      .notification {
        color: var(--warn);
      }
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        background: var(--background-color-1);

        --size: 0;
        height: var(--size);
        width: var(--size);
        margin-top: calc(var(--size) / -2);
        margin-left: calc(var(--size) / -2);
        transition: var(--transition);
      }
    }
    &:hover,
    &:global(.focus-visible) {
      .icon {
        color: var(--darkgray) !important;
        &::before {
          --size: calc(var(--height) - var(--borderSize));
        }
      }
    }
  }
  .notificationsSubmenu {
    z-index: 10;
    max-width: 30rem;
    background: var(--background-color-1);
    box-shadow: 0 3px 5px rgba(black, 0.25);
    color: var(--foreground-color-1);
    animation-duration: 0.3s;
    @include breakpoint-up(sm) {
      border-bottom-left-radius: 1em;
      border-bottom-right-radius: 1em;
    }
    @include breakpoint-down(sm) {
      width: 100%;
    }
    &.hidden {
      display: none;
    }
    > div {
      padding: 1rem;
    }
    > .title {
      padding: 1em;
      font-weight: var(--bold);
    }
    > ul {
      overflow: hidden;
      @include breakpoint-up(sm) {
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;
      }
      > li {
        position: relative;
        .bottomBtn {
          width: 100%;
          padding: 1rem;
          cursor: pointer;
          text-align: center;
          > a:focus {
            outline: none;
          }
        }
        &::before {
          content: "";
          display: block;
          position: absolute;
          left: 2px;
          right: 2px;
          top: 0;
          height: 1px;
          background: var(--foreground-color-1-alpha);
        }
        .card {
          display: grid;
          grid-template:
            "icon type" auto
            "icon link" 1fr / 5rem 1fr;
          align-items: center;
          height: 4rem;
          width: 100%;
          background: none;
          border: none;
          color: var(--foreground-color-2);
          font-weight: var(--bold);
          cursor: pointer;
          padding: 0.8em 2em 0.5em 0em;
          > .icon {
            grid-area: icon;
            justify-self: center;
          }
          > .type {
            display: flex;
            font-size: small;
            grid-area: type;
            gap: 0.5rem;
            align-items: center;
            color: var(--foreground-color-1);
            > span {
              border-radius: 0.5em;
              font-size: 0.75rem;
              height: 1.2rem;
            }
          }
          > a {
            color: var(--foreground-color-1);
            grid-area: link;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: 1rem;
            height: 1.3rem;
            margin-bottom: -0.3rem;

            &:focus {
              outline: none;
            }
          }
        }
        .card,
        .bottomBtn {
          &:hover,
          &:focus-within,
          &[aria-expanded="true"] {
            transition: all 0.3s ease;
            outline: none;
            box-shadow: 0 0 15px rgba(black, 0.25);
            padding-left: 1.5rem;
            background: var(--foreground-color-1);
            color: var(--background-color-1);
            > .type {
              color: var(--background-color-1);
            }
            a {
              color: var(--background-color-1);
              color: inherit;
            }
          }
        }
      }
    }
  }
}

:global(.nightMode) {
  .notificationsSubmenu {
    border: 1px solid var(--background-color-2);
    border-top: none;
  }
}
