@import "core/_breakpoints.scss";
.templateGrid {
  position: relative;
  z-index: 0;
  min-height: 100vh;
  display: grid;
  background-color: var(--background-color-1);
  transition: background 0.3s ease;
  &.f1 {
    background: var(--grassColor);
    .main {
      background: none !important;
      box-shadow: none;
    }
  }
  &:not(.f1) {
    .clouds {
      opacity: 0;
    }
  }
  grid-template:
    "header" auto
    "main" 1fr
    "footer" auto
    "systemStatus" auto
    / 100%;
  &.collapsed {
    .mainWrap {
      display: contents;
    }
    .main {
      box-shadow: none;
      padding: 0;
      margin: 0;
    }
  }
  .main {
    grid-area: main;
    background: var(--background-color-1) !important;
    margin: 2em 0;
    box-shadow: 0 0 0 2em var(--background-color-1);
    @include breakpoint-up(md) {
      border-radius: 1px;
    }
    &.noBackground {
      background: none !important;
      box-shadow: none;
    }
  }
  .wavesContainer {
    position: absolute;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: -1000;
  }
  .shape {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    clip-path: url(#waveShape);
    overflow: hidden;
    width: 100%;
    height: 30em;
    background-color: var(--background-color-2);
    @include breakpoint-down(md) {
      --width: 992px;
      width: var(--width);
      left: unset;
      right: 0;
    }
  }
  .footer {
    padding: 1em;
    grid-area: footer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--foreground-color-1);
  }
  &:global(.nightMode) {
    &.f1 {
      .shape {
        background-image: linear-gradient(black, var(--blue));
      }
    }
  }
}
