.breadcrumbs {
  grid-area: breadcrumbs;
  // button has height = 1.6rem, plus li margins
  min-height: 2.1rem;
  ol {
    padding: 0.5em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    li {
      margin: 0.25em 0;
    }
    .separator {
      color: var(--foreground-color-3);
      margin: 0 0.25em;
    }
  }
}
