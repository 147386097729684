@import "core/_common.scss";

span.tagcolor {
  color: var(--background-color-1);
  border: none;
  font-size: 0.75rem;
  font-weight: var(--bold);
  border-radius: calc(2 * var(--border-radius));
  align-items: center;
  min-width: 2.75rem;
  display: inline-flex;
  justify-content: center;
  height: 1.6rem;
  padding: 0.5em;
  background: var(--foreground-color-1);
  transition: var(--transition);
  @each $subject in $click-subjects {
    &.#{$subject} {
      background: var(--#{$subject});
    }
  }
  &.transparent {
    background: rgba(var(--background-color-1), 0.5);
    color: var(--foreground-color-1);
    border: solid 1px var(--foreground-color-1);
  }
}
