.wrap {
  --heightTransitionForward: 1s;
  --heightTransitionBackward: 0.5s;
  --borderRadius: 0.5em;
  position: relative;
  border-radius: var(--borderRadius);
  background: var(--foreground-color-1);
  box-shadow: 1px 1px 5px var(--foreground-color-1-alpha);

  .log {
    position: absolute;
    height: 1px;
    width: 1px;
    clip: rect(0 0 0 0);
    overflow: hidden;
    white-space: nowrap;
  }

  .staticRow {
    min-height: 5em;
    width: 100%;
    background: var(--background-color-1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em;

    .loadingIcon {
      font-size: 2em;
      color: var(--lightblue);
    }
  }

  .scrollbarTrack {
    left: 0;
    right: 0;
    z-index: 1;
  }

  .scrollbarThumb {
    background: var(--foreground-color-1-alpha);
    border-radius: 100px;
    top: -0.5em;
  }

  .table {
    width: 100%;

    > *[role="rowgroup"]:last-of-type {
      background: var(--background-color-1);
    }

    > *[role="rowgroup"] > *[role="row"] {
      display: flex;

      &:not(:last-of-type) {
        [role="gridcell"] {
          border-bottom: 1px solid var(--lightgray);

          &.pressed {
            border-bottom: 1px solid transparent;
            border-right: 1px solid var(--lightgray);
          }
        }
      }

      [role="gridcell"],
      [role="columnheader"] {
        position: relative;
        padding: 0.5em;
        white-space: nowrap;
        flex-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
        min-height: 2.5em;
        height: 2.5em;
        justify-content: flex-start;
        align-items: center;

        &.center {
          text-align: center;
          justify-content: center;
        }

        &.end {
          text-align: right;
          justify-content: flex-end;
        }

        .toggleDetails {
          background: none;
          border: none;
          color: var(--foreground-color-2);
          text-decoration: underline;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 1rem;
          font-weight: 900;
          text-align: left;
          cursor: pointer;

          &:hover {
            text-shadow: 1px 1px 2px var(--foreground-color-1-alpha);
          }

          &:active {
            transform: scale(0.95);
            transition: transform 0.3s;
          }
        }
      }

      [role="columnheader"] {
        background: var(--foreground-color-1);
        color: var(--background-color-1);
        font-weight: var(--bold);
        justify-content: flex-end;
      }

      [role="gridcell"] {
        display: flex;
        align-items: center;
        background: var(--background-color-1);
        > span,
        & > a {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &.pressed {
          border-bottom: 1px solid transparent;
          border-right: 1px solid var(--lightgray);
        }

        a {
          color: var(--foreground-color-2);
          text-decoration: underline;
          font-weight: var(--bold);
        }

        &:focus,
        *:focus {
          outline: none;

          &:global(.focus-visible) {
            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              border: 2px solid var(--foreground-color-2);
            }

            &:first-of-type {
              &::before {
                z-index: 1;
              }
            }
          }
        }
      }
    }

    .detailRow {
      padding: 0;

      & > * {
        padding: 0.5em;
      }

      &:not(.expanded) {
        transition: max-height var(--heightTransitionBackward) ease-out;
        max-height: 0;
        overflow: hidden;

        &:not(:last-of-type) {
          border-bottom: 1px solid transparent;
        }
      }

      &:not(:last-of-type) {
        box-shadow: inset 0em -0.25em 0.25em -0.25em
          var(--foreground-color-1-alpha);
        border-bottom: 1px solid var(--lightgray);
      }

      &.expanded {
        transition: max-height var(--heightTransitionForward) ease-in;
        max-height: 1000px;
      }
    }
  }
  .firstColumn {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    transition: box-shadow 0.3s;

    &.hasScrollLeft {
      box-shadow: 1px 0 5px var(--foreground-color-1-alpha);
    }

    [role="row"]:not(:last-of-type) {
      [role="gridcell"] {
        border-bottom: 1px solid var(--lightgray);

        &.pressed {
          border-bottom: 1px solid transparent;
          border-right: 1px solid var(--lightgray);
        }
      }
    }

    [role="rowgroup"]:last-of-type {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      background: var(--background-color-1);
    }

    [role="gridcell"],
    [role="columnheader"] {
      min-height: 2.5em;
      max-height: 2.5em;
      height: 2.5em;
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 0.5rem;
    }
    [role="columnheader"] {
      background: var(--foreground-color-1);
      color: var(--background-color-1);
      font-weight: var(--bold);
    }
    [role="gridcell"] {
      display: flex;
      background: var(--background-color-1);
      > span,
      & > a {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      a {
        color: var(--foreground-color-2);
        text-decoration: underline;
        font-weight: var(--bold);
      }
    }

    .fakeRow {
      border-bottom: 1px solid transparent;

      &:not(.expanded) {
        max-height: 0px;
        transition: max-height var(--heightTransitionBackward) ease-out;
      }

      &.expanded {
        max-height: 1000px;
        transition: max-height var(--heightTransitionForward) ease-in;
      }
    }
  }
}
