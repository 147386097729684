.numberInputContainer {
  --foreground-color: var(--foreground-color-1);
  --background-color: var(--background-color-1);
  --border-radius: 0.25rem;
  --border-size: 2px;
  --focus-color: var(--blackAlpha);

  color: var(--foreground-color);

  &.dark {
    --foreground-color: var(--background-color-1);
    --background-color: var(--foreground-color-1);
    --focus-color: var(--whiteAlpha);
  }
  .numberInputWrapper {
    display: inline-block;
    width: 100%;
    > .numberInput {
      font-size: 1em;
      border-radius: var(--border-radius);
      border: solid var(--border-size) var(--foreground-color);
      background: var(--background-color);
      color: var(--foreground-color);
      width: 100%;
      padding: 0.25em 0.5em;
      &::placeholder {
        color: var(--gray);
        font-style: italic;
      }
      &.noStyle {
        border: none;
        background: none;

        &:focus {
          outline: none;
        }
      }
      &:focus {
        outline: none;
        border-color: var(--blue);
      }
    }
  }

  label {
    font-weight: var(--bold);
  }
  .helpText {
    display: block;
    font-size: 0.75em;
    padding-bottom: 0.25em;
  }
}
.errorMessage {
  font-size: 0.75em;
  color: var(--danger);
  min-height: 1rem;
}
