.radioGroup {
  --size: 1em;
  --border: 2px;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  legend {
    font-weight: var(--bold);
    color: var(--foreground-color-1);
    margin: 0;
    padding: 0;
  }
  .helpText {
    display: block;
    font-size: 0.75em;
    padding-bottom: 0.25em;
    color: var(--foreground-color-1);
    margin: 0 0 0.5em 0;
  }
  /* simple */
  .inputLabel {
    display: flex;
    align-items: center;
    --margin: 0.25em;
    &:not(:last-of-type) {
      margin-bottom: calc(0.5 * var(--margin));
    }
  }
  input {
    font-size: 1em;
    position: relative;
    width: var(--size);
    height: var(--size);
    margin: 0;
    &:checked:after {
      content: "";
      --distance: 0.25em;
      position: absolute;
      left: var(--distance);
      right: var(--distance);
      top: var(--distance);
      bottom: var(--distance);
      border-radius: 500px;
      background: var(--foreground-color-2);
    }
    &:before {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      background: var(--background-color-1);
      border: var(--border) solid var(--foreground-color-1);
    }
    &:focus {
      outline: none;
    }
    &:global(.focus-visible) + label {
      &:before {
        --focusWeight: 4px;
        --leftDistance: calc(var(--size) + var(--margin) + var(--focusWeight));
        content: "";
        position: absolute;
        top: calc(-0.5 * var(--focusWeight));
        bottom: calc(-0.5 * var(--focusWeight));
        right: calc(-1 * var(--focusWeight));
        left: calc(-1 * var(--leftDistance));
        border: 4px solid var(--foreground-color-2-alpha);
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
      }
    }
  }
  label {
    align-items: center;
    position: relative;
    color: var(--foreground-color-1);
  }
  &:not(.isButton) {
    label {
      margin-left: var(--margin);
    }
  }
  /* button */
  &.isButton {
    --height: 2.5rem;
    --borderRadius: calc(var(--height) / 2);
    --borderPadding: 0.2rem;
    --borderSize: 2px;
    .inputListWrap {
      display: flex;
      flex-wrap: nowrap;
      .inputLabel {
        display: inline-flex;
        justify-content: space-around;
        align-items: center;
        margin-right: 2px;
        padding: 0;
        height: var(--height);
        &:first-of-type label {
          border-top-left-radius: var(--borderRadius);
          border-bottom-left-radius: var(--borderRadius);
        }
        &:last-of-type label {
          border-top-right-radius: var(--borderRadius);
          border-bottom-right-radius: var(--borderRadius);
          margin-right: 0;
        }
        input {
          position: absolute;
          left: -10000px;
          &:checked + label {
            background: var(--foreground-color-2);
            border-color: var(--foreground-color-2);
          }
          &:not(:checked) + label {
            cursor: pointer;
          }
          &:global(.focus-visible) + label,
          &:hover + label {
            box-shadow: 1px 1px 5px rgba(black, 0.5);
            z-index: 0;
          }
          &:global(.focus-visible) + label {
            &:before {
              --distance: calc(-3 * var(--borderSize));

              content: "";
              position: absolute;
              top: var(--distance);
              bottom: var(--distance);
              left: var(--distance);
              right: var(--distance);

              border: calc(2 * var(--borderSize)) solid var(--blueAlpha);
              height: unset;
              width: unset;
              border-radius: unset;
            }
          }
        }
        &:first-of-type input:global(.focus-visible) + label:before {
          border-bottom-left-radius: calc(2 * var(--borderRadius));
          border-top-left-radius: calc(2 * var(--borderRadius));
        }
        &:last-of-type input:global(.focus-visible) + label:before {
          border-bottom-right-radius: calc(2 * var(--borderRadius));
          border-top-right-radius: calc(2 * var(--borderRadius));
        }
      }
      label {
        display: flex;
        position: relative;
        z-index: 1;
        background: var(--foreground-color-1);
        color: var(--background-color-1);
        border: var(--borderSize) solid var(--foreground-color-1);
        padding: 0.25em;
        height: var(--height);
        font-weight: var(--bold);
        font-size: calc(var(--height) / 2.5);
        padding: 0 var(--borderRadius);
        &.icon {
          padding: 0.75em;
        }
      }
    }
  }
  &.dark {
    label,
    legend,
    .helpText {
      color: var(--background-color-1);
    }
    input:before {
      border: 1px solid var(--background-color-1);
    }
    &.isButton {
      label {
        background: var(--background-color-1);
        border: var(--borderSize) solid var(--background-color-1);
        color: var(--foreground-color-1);
      }
      input {
        &:checked + label {
          color: var(--background-color-1);
        }
      }
    }
  }
}

.errorMessage {
  font-size: 0.75em;
  color: var(--danger);
  min-height: 1rem;
}
