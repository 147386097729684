@import "core/_breakpoints.scss";

.bodyOverflow {
  overflow: hidden;
}

.wrap,
.rootBlur {
  --transition-duration: 0.2s;
}

.rootBlur {
  filter: grayscale(1) opacity(0.25);
  transition: all var(--transition-duration);
}

.wrap {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: var(--blackAlpha);
  z-index: var(--modalIndex);
  animation-duration: var(--transition-duration);

  .modal {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--gray);
    border-radius: calc(2 * var(--border-radius));
    background: var(--white);
    box-shadow: 1px 1px 5px rgba(black, 0.5);
    animation-duration: var(--transition-duration);
    --margin: 80px;
    max-height: calc(100% - var(--margin));
    max-width: calc(100% - var(--margin));

    --padding: 2em;
    padding: var(--padding);

    @include breakpoint-down(sm) {
      --margin: 40px;
    }
    .content {
      display: flex;
      flex-shrink: 1;
      height: 100%;
      min-height: 0;
    }
    .content:not(:last-child) {
      margin-bottom: 1em;
    }

    .footer {
      display: flex;
      justify-content: flex-end;
    }

    .close {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
