.wrap {
  box-shadow: 1px 1px 5px var(--foreground-color-1-alpha);
  background: var(--foreground-color-1);
  border-radius: 0.5em;
  overflow: hidden;
  position: relative;
  .filter {
    border-radius: 0;
    margin-bottom: 1em;
  }
  .table {
    border-radius: 0;
    box-shadow: none;
  }
}
