@import "core/_common.scss";
.LoginContainer {
  display: flex;
  justify-content: center;
  form {
    background: var(--background-color-1);
    border-radius: 1em;
    width: 400px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
    padding: 1.5rem;
    h1 {
      margin: 0;
    }
    .error {
      color: var(--danger);
    }

    .passwordInput {
      position: relative;
      width: 100%;
      padding-right: 3rem;
    }

    .passwordButton {
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      height: 2rem;
    }

    .lostPassword {
      align-self: flex-start;
      font-size: small;
    }

    .loginButton {
      align-self: flex-end;
    }

    .FormInput {
      width: 100%;

      input {
        border: 1px solid var(--darkgray);
        border-radius: var(--border-radius);
        background: var(--white);
        min-height: 2rem;
        width: 100%;
        transition: all 0.3s;
        font-size: 1rem;
        height: 2rem;
        padding: 0.25rem 0.5rem;
      }
    }
  }
}
