.tooltipWrap {
  z-index: var(--tooltipIndex);
  .tooltipContainer {
    animation-duration: 0.3s;
    animation-delay: 0.2s;
    background: var(--lightwarn);
    color: var(--darkgray);
    padding: 0.5em;
    font-size: 0.75em;
    border-radius: 0.25em;
    filter: drop-shadow(2px 2px 2px rgba(black, 0.5));
    &.vertical {
      margin: 0.75em 0;
    }
    &.horizontal {
      margin: 0 0.75em;
    }
    .tooltipArrow {
      --size: 5px;
      --position: calc(-1 * var(--size));
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border: var(--size) solid transparent;
      &.top {
        bottom: var(--position);
        border-top-color: var(--lightwarn);
        border-bottom-width: 0;
        &::before {
          border-top-color: var(--white);
          border-bottom-width: 0;
          margin-left: var(--position);
          margin-top: var(--position);
          transform: translateY(2px);
        }
      }
      &.bottom {
        top: var(--position);
        border-bottom-color: var(--lightwarn);
        border-top-width: 0;
      }
      &.left {
        right: var(--position);
        border-left-color: var(--lightwarn);
        border-right-width: 0;
      }
      &.right {
        left: var(--position);
        border-right-color: var(--lightwarn);
        border-left-width: 0;
      }
    }
  }
}
