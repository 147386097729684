@import "core/_breakpoints.scss";
.menuWrapper {
  grid-area: menu;
}
.menu {
  &.collapsed {
    display: none;
  }
  a {
    text-decoration: none;
  }
  --upperMenuHeight: 60px;
  --lowerMenuHeight: 60px;
  grid-area: menu;
  display: grid;
  align-items: center;
  grid-template:
    "navigation search notifications user" var(--upperMenuHeight) /
    auto 1fr auto auto;
  @include breakpoint-up(md) {
    grid-template:
      "social search notifications user" var(--upperMenuHeight)
      "navigation navigation navigation navigation" var(--lowerMenuHeight) /
      auto 1fr auto auto;
  }
}
