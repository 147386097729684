.error {
  color: var(--danger);
  display: block;
  font-size: 0.75em;
  height: 1.5em;
}
.label {
  font-weight: var(--bold);
}

:global(.ck-content),
:global(.ck-editor),
:global(.ck-body),
:global(.ck-toolbar) {
  /* Overrides the border radius setting in the theme. */
  --ck-border-radius: 0.25rem;

  /* Overrides the default font size in the theme. */
  --ck-font-size-base: 0.75rem;

  /* Helper variables to avoid duplication in the colors. */
  --ck-custom-background: var(--black);
  --ck-custom-foreground: var(--black);
  --ck-custom-border: var(--black);
  --ck-custom-white: var(--white);

  /* -- Overrides generic colors. ------------------------------------------------------------- */

  --ck-color-base-foreground: var(--black);
  --ck-color-text: var(--white);
  --ck-color-shadow-drop: var(--blackAlpha);
  --ck-color-shadow-inner: var(--blackAlpha);
  --ck-color-base-background: var(--background-color-1);

  /* -- Overrides the default .ck-button class colors. ---------------------------------------- */

  --ck-color-button-default-background: var(--black);
  --ck-color-button-default-hover-background: var(--gray);
  --ck-color-button-default-active-background: var(--gray);
  --ck-color-button-default-active-shadow: var(--blackAlpha);
  --ck-color-button-default-disabled-background: var(--ck-custom-background);

  --ck-color-button-on-background: var(--black);
  --ck-color-button-on-hover-background: var(--black);
  --ck-color-button-on-active-background: var(--black);
  --ck-color-button-on-active-shadow: var(--blackAlpha);
  --ck-color-button-on-disabled-background: var(--ck-custom-foreground);

  --ck-color-button-action-background: var(--blue);
  --ck-color-button-action-hover-background: var(--darkblue);
  --ck-color-button-action-active-background: var(--darkblue);
  --ck-color-button-action-active-shadow: var(--darkblue);
  --ck-color-button-action-disabled-background: var(--blue);
  --ck-color-button-action-text: var(--ck-custom-white);

  --ck-color-button-save: var(--lightsuccess);
  --ck-color-button-cancel: var(--lightdanger);

  /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

  --ck-color-dropdown-panel-background: var(--black);
  --ck-color-dropdown-panel-border: var(--black);

  /* -- Overrides the default .ck-input class colors. ----------------------------------------- */

  --ck-color-input-background: var(--white);
  --ck-color-input-border: var(--gray);
  --ck-color-input-text: var(--white);
  --ck-color-input-disabled-background: var(--lightgray);
  --ck-color-input-disabled-border: var(--grayAlpha);
  --ck-color-input-disabled-text: var(--grayAlpha);

  /* -- Overrides the default .ck-list class colors. ------------------------------------------ */

  --ck-color-list-background: var(--black);
  --ck-color-list-button-hover-background: var(--darkblue);
  --ck-color-list-button-on-background: var(--blue);
  --ck-color-list-button-on-background-focus: var(--darkblue);
  --ck-color-list-button-on-text: var(--white);

  /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

  --ck-color-panel-background: var(--black);
  --ck-color-panel-border: var(--black);

  /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

  --ck-color-toolbar-background: var(--ck-custom-background);
  --ck-color-toolbar-border: var(--whiteAlpha);

  /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

  --ck-color-tooltip-background: var(--black);
  --ck-color-tooltip-text: var(--white);

  /* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

  --ck-color-image-caption-background: var(--white);
  --ck-color-image-caption-text: var(--black);

  /* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

  --ck-color-widget-blurred-border: var(--white);
  --ck-color-widget-hover-border: var(--blue);
  --ck-color-widget-editable-focus-background: var(--ck-custom-white);

  /* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */

  --ck-color-link-default: var(--blue);

  --ck-z-modal: var(--click-ck-modal);
  --ck-color-panel-border: var(--white);

  :global(.ck.ck-input-text) {
    color: var(--darkgray);
  }

  :global(.table) {
    table {
      border-color: var(--darkgray);
      tr td {
        border: 1px solid var(--gray);
      }
      tr th {
        background-color: var(--darkgray);
        border: 1px solid var(--black);
        color: var(--white);
        --ck-color-table-focused-cell-background: var(--blue);
      }
    }
  }

  :global(ul) {
    list-style: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
}
