@import "core/_breakpoints.scss";
@include breakpoint-down(sm) {
  [aria-expanded="false"] ~ .menubarWrap {
    display: none;
  }
}
.menubarWrap {
  @include breakpoint-up(md) {
    //disables scrollbars
    > div {
      > div:not(:first-child) {
        display: none;
      }
      > div:first-child {
        display: contents;
      }
    }
  }
  @include breakpoint-down(sm) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 15%;
    background: var(--background-color-2);
    z-index: var(--menuIndex);
    box-shadow: 0 0 5px rgba(black, 0.25);
  }
  .menubar {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    @include breakpoint-down(sm) {
      height: 100vh;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    @include breakpoint-up(md) {
      &::before {
        // horizontal line that splits menu in two
        content: "";
        position: absolute;
        display: block;
        height: 1px;
        width: 300vw;
        left: -100vw;
        top: 0;
        background: var(--foreground-color-3);
        opacity: 0.5;
      }
    }
    .menubarLogoWrap {
      margin-right: auto;
      @include breakpoint-down(sm) {
        padding: 1em;
      }
    }
    .menubarItemWrap {
      position: relative;
      @include breakpoint-down(sm) {
        width: 100%;
      }
      @include breakpoint-up(md) {
        margin-left: 1px;
        height: 60px;
      }
      .menubarItemSubmenu {
        @include breakpoint-down(sm) {
          width: 100%;
          position: relative !important;
          transform: none !important;
        }
        .menubarItemSubmenu {
          box-shadow: 0 0 5px rgba(black, 0.25),
            inset 0.5em 0 0 -1px var(--foreground-color-1);
          padding-left: 0.5em;
        }
      }
      .menubarItem {
        @include breakpoint-down(sm) {
          height: 60px;
          width: 100%;
          justify-content: flex-start;
        }
        cursor: pointer;
        position: relative;
        overflow: hidden;
        border: none;
        background: none;
        color: var(--foreground-color-3);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px 0 21px;
        height: 100%;
        .submenuIndicator {
          position: absolute;
          height: 1em;
          width: 1em;
          bottom: 0.25em;
          left: 50%;
          margin-left: -0.5em;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease;
          &.selected {
            transform: rotate(180deg);
          }
          @include breakpoint-down(sm) {
            top: 50%;
            margin-top: -0.5em;
            right: 1.5em;
            left: unset;
            bottom: unset;
            margin-left: 0;
          }
        }
        &::after {
          content: "";
          display: flex;
          position: absolute;
          z-index: -1;
          height: 0;
          width: 0;
          left: 50%;
          top: 50%;
          border-radius: 1000px;
          border: 0 solid var(--warn);
          background: var(--background-color-1);
        }
        &[aria-expanded="true"]:not(:hover):not(:global(.focus-visible)) {
          background: var(--background-color-3);
        }
        &:focus,
        &:global(.focus-visible) {
          outline: none;
        }
        &:hover,
        &:global(.focus-visible) {
          color: var(--foreground-color-1);
          transition: all 0.3s ease-in;
          box-shadow: 8px 0 5px -5px var(--background-color-3-alpha),
            -8px 0 5px -5px var(--background-color-3-alpha);
          &::after {
            transition: all 0.3s ease-in;
            --size: 600px;
            height: var(--size);
            width: var(--size);
            margin-top: calc(-0.5 * var(--size));
            margin-left: calc(-0.5 * var(--size));
            border-width: 100px;
          }
        }
      }
      @mixin env-menu-divisor {
        --divisorHeight: 30px;
        content: "";
        display: block;
        position: absolute;
        height: var(--divisorHeight);
        width: 1px;
        background: var(--foreground-color-3);
        opacity: 0.5;
        top: 50%;
        margin-top: calc(-0.5 * var(--divisorHeight));
        @include breakpoint-down(sm) {
          height: 1px;
          width: var(--divisorHeight);
          margin-top: 0;
          left: 50%;
          margin-left: calc(-0.5 * var(--divisorHeight));
          top: unset;
        }
      }
      &::before {
        @include env-menu-divisor;
        left: -1px;
        @include breakpoint-down(sm) {
          top: 0;
        }
      }
      &:last-child {
        &::after {
          @include env-menu-divisor;
          right: 0;
          @include breakpoint-down(sm) {
            bottom: 0;
            right: unset;
          }
        }
      }
      &:focus-within,
      &:hover {
        @mixin divisorTransition {
          transition: all 0.3s ease;
          @include breakpoint-up(md) {
            height: 100%;
            top: 0;
            margin-top: 0;
          }
          @include breakpoint-down(sm) {
            width: 100%;
            left: 0;
            margin-left: 0;
          }
        }
        &::before,
        & + *::before {
          @include divisorTransition;
        }
        &:last-child {
          &::after {
            @include divisorTransition;
          }
        }
      }
    }
  }
}

:global(.nightMode) {
  .menubarItem:not(:hover)[aria-expanded="true"] {
    color: var(--background-color-1);
  }
}
