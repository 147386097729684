.heading {
  font-weight: normal;
  font-family: "VAGRounded", var(--fontFamily) !important;
  color: var(--foreground-color-1);
  // font-weight: var(--bold);
  margin-block-start: 0.75em;
  margin-block-end: 0.75em;
  &h1 {
    font-size: 2.75em;
  }
  &h2 {
    font-size: 1.5em;
  }
  &h3,
  &h4 {
    font-size: 1.25em;
  }
  span {
    text-shadow: 1px 1px 0 var(--background-color-3);
    color: var(--foreground-color-2);
  }
}
