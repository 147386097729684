.searchBar {
  grid-area: search;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin-right: 0.5em;
  .searchInput {
    border: none;
    height: 30px;
    width: 100%;
    max-width: 200px;
    border-radius: 100px;
    background: var(--background-color-3-alpha);
    color: var(--background-color-1);
    padding: 0 1em;
    &::placeholder {
      color: var(--background-color-1);
      font-style: italic;
    }
    &:focus {
      outline: none;
    }
    &:global(.focus-visible) {
      box-shadow: 0 0 0 5px var(--background-color-1-alpha);
    }
  }
  .searchButton {
    position: absolute;
    right: 0;
  }
}
