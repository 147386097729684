@keyframes click-slideDown {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    max-height: 100vh;
  }
  to {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
    max-height: 0;
  }
}
@keyframes click-slideDownReverse {
  from {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
    max-height: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    max-height: 100vh;
  }
}

@keyframes click-slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes click-slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
